<template>
  <modal name="ShortPlaneDetailsPopup" transition="pop-out" @closed="hide">
    <div class="requestsDashboardDetail">
      <div
        class="requestsDashboardDetail__inner"
      >
        <div class="requestsDashboardDetail__title">Plane Info</div>
        <div class="requestsDashboardDetail__info" v-if="planeInfo">
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Operator name</div>
            <div class="requestsDashboardDetail__info-item-value">{{planeInfo.companyName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Aircraft type
            </div>
            <div class="requestsDashboardDetail__info-item-value">{{planeInfo.type}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Aircraft model</div>
            <div class="requestsDashboardDetail__info-item-value">{{planeInfo.model}}</div>
          </div>
        </div>
      </div>

      <button class="fancybox-button fancybox-close-small" @click="hide"></button>
    </div>
  </modal>
</template>

<script>
  import './RequestsDetailPopup.styl'

  export default {
    name: 'ShortPlaneDetailsPopup',
    props: ['planeInfo'],
    methods: {
      hide () {
        this.$modal.hide('ShortPlaneDetailsPopup')
        this.$emit('clearInfo')
      },
    },
  }
</script>
